import './bootstrap';
import '../css/app.css';

import.meta.glob([
	'../images/**'
]);

import $ from 'jquery';
window.$ = window.jQuery = $;

document.addEventListener('DOMContentLoaded', function () {
	function setDarkClass() {
		const isDark = localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
		if (isDark) {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}
	}

	// Set initial theme
	setDarkClass();

	// Watch for system theme changes
	window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setDarkClass);
});

// Define the function in the global scope
window.openEditBookingTab = function(url) {
	console.log("Opening URL:", url); // Log the URL being opened
	var newTab = window.open(url, '_blank'); // Open the edit booking form in a new tab

	if (newTab) {
		newTab.focus(); // Bring the new tab into focus
	} else {
		alert('Please allow popups for this website to edit the booking.');
	}
};

jQuery(document).ready(function($) {
	let isDirty = false; // Flag to track if there are unsaved changes

	// Track changes in the form
	$('#update-form input, #update-form forms.textarea, #update-form select').on('change keyup input', function() {
		isDirty = true; // Mark the form as dirty
		$('#success-message').fadeOut(); // Hide the success message
	});

	// Show success message and clear dirty flag on submit
	$('#update-form').on('submit', function() {
		isDirty = false; // Reset the dirty flag on submit
	});

	// Prevent tab closure if there are unsaved changes
	window.onbeforeunload = function() {
		if (isDirty) {
			return 'You have unsaved changes. Are you sure you want to leave?'; // Confirmation message
		}
	};
});




